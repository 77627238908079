import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { fetchTopMainNews } from 'common/redux/commonData/widgets/topMainWidget/asyncs';
import {
  selectWidgetsTopMainClusterInCardData,
  selectWidgetsTopMainErrorState,
  selectWidgetsTopMainLoadingState,
} from 'common/redux/commonData/widgets/topMainWidget/selectors';
import { selectClusterPageLoadingState } from 'common/redux/newPages/cluster/selectors';
import { useAppDispatch } from 'store/hooks';

type UseTopMainNewsWidgetType = (excludeIds: CardData['id'][]) => {
  /**
   * Массив кластеров для отображения
   */
  clusters: CardData[];
  /**
   * Состояние виджета (загружается или нет)
   */
  isLoading: boolean;
  /**
   * Состояние виджета (есть ошибка или нет)
   */
  isError: boolean;
  /**
   * Функция загрузки данных
   */
  fetchData: () => void;
};

/**
 * Хук для виджета последних новостей.
 * @param props.excludeIds - исключаемые из отображения id.
 */
export const useTopMainNewsWidget: UseTopMainNewsWidgetType = (excludeIds) => {
  const dispatch = useAppDispatch();

  const clusters = useSelector(
    selectWidgetsTopMainClusterInCardData,
    shallowEqual,
  );
  const isLoading = useSelector(selectWidgetsTopMainLoadingState);
  const error = useSelector(selectWidgetsTopMainErrorState);
  const clusterPageLoading = useSelector(selectClusterPageLoadingState);

  const fetchData = useCallback(() => {
    if (!clusterPageLoading) {
      dispatch(fetchTopMainNews({ excludeIds }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, clusterPageLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    clusters: clusters.filter(Boolean) as CardData[],
    isLoading,
    isError: !!error,
    fetchData,
  };
};
