import { useCallback, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import {
  fetchTagFinanceNews,
  fetchTopicFinanceNews,
} from 'common/redux/commonData/widgets/topMainWidget/asyncs';
import {
  selectWidgetsTopMainClusterInCardData,
  selectWidgetsTopMainErrorState,
  selectWidgetsTopMainLoadingState,
} from 'common/redux/commonData/widgets/topMainWidget/selectors';
import {
  CURRENCY_CHAR_CODE,
  FINANCE_TAG_WIDGET,
} from 'config/constants/finance';
import { ECONOMICS_ID } from 'config/constants/topics';
import { useAppDispatch } from 'store/hooks';

type UseFinanceNewsWidgetType = ({
  currency,
}: {
  currency: CURRENCY_CHAR_CODE;
}) => {
  /**
   * Массив кластеров для отображения
   */
  clusters: CardData[];
  /**
   * Состояние виджета (загружается или нет)
   */
  isLoading: boolean;
  /**
   * Состояние виджета (есть ошибка или нет)
   */
  isError: boolean;
  /**
   * Функция загрузки данных
   */
  fetchData: () => void;
};

/**
 * Хук для виджета на странице финансов.
 * @param props.currency - код валюты
 */
export const useFinanceNewsWidget: UseFinanceNewsWidgetType = ({
  currency,
}) => {
  const dispatch = useAppDispatch();

  const clusters = useSelector(
    selectWidgetsTopMainClusterInCardData,
    shallowEqual,
  );
  const isLoading = useSelector(selectWidgetsTopMainLoadingState);
  const error = useSelector(selectWidgetsTopMainErrorState);

  const fetchData = useCallback(() => {
    const isUSD = currency === CURRENCY_CHAR_CODE.USD;
    const isEUR = currency === CURRENCY_CHAR_CODE.EUR;

    if (isUSD || isEUR) {
      const tagAlias = isUSD
        ? FINANCE_TAG_WIDGET.Dollar
        : FINANCE_TAG_WIDGET.Evro;

      dispatch(fetchTagFinanceNews({ tagAlias }));
    } else {
      dispatch(fetchTopicFinanceNews({ topicId: ECONOMICS_ID }));
    }
  }, [dispatch, currency]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    clusters: clusters.filter(Boolean) as CardData[],
    isLoading,
    isError: !!error,
    fetchData,
  };
};
