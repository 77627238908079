import { PROJECT_IDS } from 'config/constants/projects/constants';

// Мапка [id проекта]: <название проекта в деепричастном роду>
export const PROJECT_ALIASES_IN_RELATIVE = {
  [PROJECT_IDS.News]: 'Главные новости',
  [PROJECT_IDS.Auto]: 'Главное из автомира',
  [PROJECT_IDS.Doctor]: 'Главное о здоровье',
  [PROJECT_IDS.Finance]: 'Главное о финансах',
  [PROJECT_IDS.Kino]: 'Главное из мира кино',
  [PROJECT_IDS.Sport]: 'Главное о спорте',
  [PROJECT_IDS.Travel]: 'Главное о путешествиях',
  [PROJECT_IDS.Weekend]: 'Главное на «Субботнем»',
  [PROJECT_IDS.Woman]: 'Главное на «Женском»',
};
