import { CURRENCY_CHAR_CODE } from 'config/constants/finance';

export const getWidgetTitle = (currency: CURRENCY_CHAR_CODE) => {
  if (currency === CURRENCY_CHAR_CODE.USD) {
    return { title: 'Новости о курсе доллара', path: '/tag/dollar/' };
  }

  if (currency === CURRENCY_CHAR_CODE.EUR) {
    return { title: 'Новости о курсе евро', path: '/tag/evro/' };
  }

  return { title: 'Курсы валют', path: '/economics/' };
};
