import React, { memo } from 'react';

import { RnetWidgetSkeleton } from 'desktop/components/Cluster/RnetNewsWidget/RnetWidgetSkeleton';
import { TopWidgetSkeleton } from 'desktop/components/TopNewsWidget/TopWidgetSkeleton';

import { WIDGET_TYPE } from '../constants';

import s from './styles.module.css';

type SkeletonsPropsType = {
  arraySkeletons: string[];
};

/**
 * Скелетон для виджетов
 * @param props.arraySkeletons - массив скелетонов;
 */
export const Skeletons = memo(({ arraySkeletons }: SkeletonsPropsType) => (
  <div className={s.skeletons}>
    {arraySkeletons.map((skeleton: string) => (
      <div key={Math.random()} className={s.skeleton}>
        {skeleton === WIDGET_TYPE.top && <TopWidgetSkeleton />}
        {skeleton === WIDGET_TYPE.rnet && <RnetWidgetSkeleton />}
      </div>
    ))}
  </div>
));
